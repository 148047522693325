<template>
  <el-menu
    :default-active="$route.meta.activeMenu || $route.path"
    class="el-menu-vertical-demo, leftMenu"
    background-color="#fff"
    active-text-color="#1E469A"
  >
    <sidebarItem
      v-for="route in $store.getters['menu/sidebarRoutes']"
      :key="route.path"
      :item="route"
      :base-path="route.path"
    ></sidebarItem>
  </el-menu>
</template>

<script>
import sidebarItem from './sidebarItem.vue'
export default {
  name: 'SlidebarIndex',
  components: {
    sidebarItem
  },
  data () {
    return {}
  },
  mounted () {
    console.log('ssMenu', this.$store.getters['menu/sidebarRoutes'])
  }
}
</script>

<style scoped>
.leftMenu {
  width: 190px;
  height: 100%;
}
.el-menu {
  border-right: 0px !important;
}
</style>
