import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import EmptyLayout from '@/layout/empty'

Vue.use(VueRouter)

const constantRoutes = [
  {
    path: '/',
    component: Layout
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue')
  },
  {
    path: '/timeDetail',
    component: Layout,
    children: [
      {
        path: '/timeDetail',
        name: 'timeDetail',
        component: () => import('@/views/consult/timeDetail')
      }
    ]
  }
]
// ..路由
const asyncRoutes = [
  {
    path: '/examine',
    name: 'examine',
    component: Layout,
    redirect: '/examine/dispute',
    subset: true,
    meta: {
      title: '审核列表',
      icon: require('@/assets/images/icon1.png'),
      id: 1
    },
    children: [
      {
        path: 'dispute',
        name: 'dispute',
        redirect: '/examine/dispute',
        component: EmptyLayout,
        meta: {
          title: '纠纷调解申请',
          id: 18,
          parent_id: 1
        },
        children: [
          {
            path: '',
            name: 'disputeIndex',
            component: () => import('@/views/dispute/index.vue'),
            meta: {
              id: 19,
              parent_id: 18,
              sidebar: false,
              activeMenu: '/examine/dispute'
            }
          },
          {
            path: 'detail',
            name: 'disputeDetail',
            component: () => import('@/views/consult/detail.vue'),
            meta: {
              id: 20,
              parent_id: 18,
              sidebar: false,
              activeMenu: '/examine/dispute'
            }
          }
        ]
      },
      {
        path: 'consult',
        name: 'consult',
        redirect: '/examine/consult',
        component: EmptyLayout,
        meta: {
          title: '侵权判定咨询',
          id: 24,
          parent_id: 1
        },
        children: [
          { // 列表
            path: '',
            name: 'consultIndex',
            component: () => import('@/views/consult/index.vue'),
            meta: {
              id: 25,
              parent_id: 24,
              sidebar: false,
              activeMenu: '/examine/consult'
            }
          },
          { // 详情
            path: 'detail',
            name: 'consultDetail',
            component: () => import('@/views/consult/detail.vue'),
            meta: {
              id: 26,
              parent_id: 24,
              sidebar: false,
              activeMenu: '/examine/consult'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/user',
    component: Layout,
    meta: {
      title: '用户列表',
      icon: require('@/assets/images/icon2.png'),
      id: 3
    },
    redirect: '/user',
    children: [
      {
        path: '',
        name: 'user',
        meta: {
          title: '用户列表',
          id: 43,
          sidebar: false,
          activeMenu: '/user',
          parent_id: 3
        },
        component: () => import('@/views/userList/index.vue')
      }
    ]
  },
  { // moban
    path: '/template',
    name: 'template',
    component: Layout,
    redirect: '/template/message',
    subset: true,
    meta: {
      title: '模板管理',
      icon: require('@/assets/images/icon3.png'),
      id: 2
    },
    children: [
      {
        path: 'message',
        name: 'message',
        redirect: '/template/message',
        component: EmptyLayout,
        meta: {
          title: '短信模板',
          id: 30,
          parent_id: 2
        },
        children: [
          {
            path: '',
            name: 'messageIndex',
            component: () => import('@/views/message/index.vue'),
            meta: {
              id: 31,
              parent_id: 30,
              sidebar: false,
              activeMenu: '/template/message'
            }
          }
        ]
      },
      {
        path: 'reject',
        name: 'reject',
        redirect: '/template/reject',
        component: EmptyLayout,
        meta: {
          title: '驳回模板',
          id: 36,
          parent_id: 2
        },
        children: [
          {
            path: '',
            name: 'rejectIndex',
            component: () => import('@/views/reject/index.vue'),
            meta: {
              id: 37,
              parent_id: 36,
              sidebar: false,
              activeMenu: '/template/reject'
            }
          }
        ]
      },
      { // ！！待改
        path: 'mediate',
        name: 'mediate',
        redirect: '/template/mediate',
        component: EmptyLayout,
        meta: {
          title: '纠纷调解模板',
          id: 46, // 等role 添加后改id！！数
          parent_id: 2
        },
        children: [
          {
            path: '',
            name: 'mediateIndex',
            component: () => import('@/views/mediate/index.vue'),
            meta: {
              id: 47,
              parent_id: 46,
              sidebar: false,
              activeMenu: '/template/mediate'
            }
          }
        ]
      },
      { // ！！待改
        path: 'tort',
        name: 'tort',
        redirect: '/template/tort',
        component: EmptyLayout,
        meta: {
          title: '侵权判定模板',
          id: 52, // 等role 添加后改id！！数
          parent_id: 2
        },
        children: [
          {
            path: '',
            name: 'tortIndex',
            component: () => import('@/views/tort/index.vue'),
            meta: {
              id: 53,
              parent_id: 52,
              sidebar: false,
              activeMenu: '/template/tort'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/system',
    name: 'system',
    component: Layout,
    subset: true,
    redirect: '/system/administrator',
    meta: {
      title: '系统管理',
      icon: require('@/assets/images/icon5.png'),
      id: 4
    },
    children: [
      {
        path: 'administrator',
        name: 'administrator',
        redirect: '/system/administrator',
        component: EmptyLayout,
        meta: {
          title: '管理员管理',
          id: 11,
          parent_id: 4
        },
        children: [
          {
            path: '',
            name: 'administratorIndex',
            component: () => import('@/views/administrator/index.vue'),
            meta: {
              id: 12,
              parent_id: 11,
              sidebar: false,
              activeMenu: '/system/administrator'
            }
          }
        ]
      },
      {
        path: 'role',
        name: 'role',
        redirect: '/system/role',
        component: EmptyLayout,
        meta: {
          title: '角色管理',
          id: 5,
          parent_id: 4
        },
        children: [
          {
            path: '',
            name: 'roleIndex',
            component: () => import('@/views/role/index.vue'),
            meta: {
              id: 6,
              parent_id: 5,
              sidebar: false,
              activeMenu: '/system/administrator'
            }
          }
        ]
      },
      {
        path: 'adminLog',
        name: 'adminLog',
        component: () => import('@/views/adminLog/index.vue'),
        meta: {
          title: '操作日志',
          parent_id: 4
          // id:
        }
      }
    ]
  }
]

// 解决路由在 push/replace 了相同地址报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
export { asyncRoutes, constantRoutes }

export default router
