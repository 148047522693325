<template>
  <div style="background: #f8f9fd">
    <!-- 头部 -->
    <el-header>
      <top></top>
    </el-header>
    <!-- 下半主体部分 -->
    <el-container class="cont">
      <!-- 左侧导航 -->
      <!-- <el-aside class="aside">
        <asideNav></asideNav>
      </el-aside> -->

      <!-- new左侧导航 -->
      <!-- <el-menu :default-active="this.$route.path">
        <div name="sidebar">
          <template v-for="route in $store.getters['menu/sidebarRoutes']"> -->
              <!-- 先删v-if="route.meta.sidebar !== false" -->
            <!-- <SidebarItem
              :key="route.path"
              :item="route"
              :base-path="route.path"
            />
          </template>
        </div>
      </el-menu> -->

<!-- nnew左 -->
      <el-aside class="aside">
        <Sidebar></Sidebar>
      </el-aside>

      <!-- 右侧main -->
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Sidebar from './components/sidebar.vue'
import top from "./components/header.vue";
import asideNav from "@/layout/components/asideNav";
export default {
  data() {
    return {};
  },
  components: {
    top,
    asideNav,
    Sidebar
  },
  mounted() {
    console.log("path", this.$route.path);
    console.log("storeee", this.$store.getters["menu/sidebarRoutes"]);
  },
  methods: {},
};
</script>

<style scoped>
.el-header {
  padding: 0px;
}
.aside {
  /* box-sizing: border-box; */
  width: 215px !important;
  padding-top: 18px;
  padding-left: 14px;
  background: #fff;
}
.cont {
  margin-top: 20px;
}
.cont .el-main {
  padding: 0%;
}
</style>