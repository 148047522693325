/*
 * @Description: 挂载路由导航守卫
 */
import VueRouter from 'vue-router'
import router, { constantRoutes, asyncRoutes } from './router'
import { MessageBox } from 'element-ui'

import store from '@/store/index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ easing: 'ease', showSpinner: false })

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  console.log('storestore', store)
  // 已经登录，但还没根据权限动态挂载路由
  if (store.state.user.token && !store.state.menu.isGenerate) {
    /**
     * 重置 matcher
     * https://blog.csdn.net/baidu_28647571/article/details/101711682
     */
    console.log('qwe')
    router.matcher = new VueRouter({
      routes: constantRoutes
    }).matcher

    // 动态新增路由
    const accessRoutes = await store.dispatch('menu/generateRoutes', {
      asyncRoutes,
      currentPath: to.path
    })
    console.log('前accessRoutes', accessRoutes)

    accessRoutes.forEach(item => {
      router.addRoute(item)
    })

    router.addRoute({ path: '*', redirect: '/404' })
    console.log('加后的routere', router)
    next({ ...to, replace: true })
  }

  // 根据路由判断属于哪个头部导航
  if (store.state.menu.isGenerate) {
    store.commit('menu/setHeaderActived', to.path)
  }

  // 权限跳转
  if (store.state.user.token) {
    // 登录后后台没有给它配置权限
    if (store.state.menu.routes.length === 0) {
      MessageBox.alert('您没有相关权限，请联系管理员', '提示', {
        showClose: false
      }).then(() => {
        // 强制登出
        store.dispatch('user/logout')
      })
      return
    }

    // 如果未开启控制台页面，则默认进入侧边栏导航第一个模块
    if (to.path === '/') {
      // 判断第一个是有子集的还是没有
      const route = store.getters['menu/sidebarRoutes'][0]
      const path = route.subset ? route.redirect : route.path
      console.log(path, 'path')
      next({
        path,
        replace: true
      })
    }
  } else {
    localStorage.removeItem('token')
    console.log('213')
    if (to.path !== '/login') {
      next({
        path: '/login'
      })
    }
  }

  next()
})

router.afterEach(() => {
  NProgress.done()
})
