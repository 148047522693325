<template>
  <div>
    <el-menu
      :default-active="this.$route.path"
      class="el-menu-vertical-demo, leftMenu"
      background-color="#fff"
      active-text-color="#1E469A"
    >
      <el-submenu index="1">
        <template slot="title">
          <img style="margin-right: 8px" src="@/assets/images/icon1.png" alt="">
          <span>审核列表</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/dispute" @click="onGo('/dispute')">纠纷调解申请</el-menu-item>
          <el-menu-item index="/consult" @click="onGo('/consult')">侵权判定咨询</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-menu-item index="/user" @click="onGo('/user')">
        <img style="margin-right: 8px" src="@/assets/images/icon2.png" alt="">
        <span slot="title">用户列表</span>
      </el-menu-item>
<!--  -->
      <el-submenu index="4">
        <template slot="title">
          <img style="margin-right: 8px" src="@/assets/images/icon3.png" alt="">
          <span>模板管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/reject" @click="onGo('/reject')">驳回模板</el-menu-item>
          <el-menu-item index="/message" @click="onGo('/message')">短信模板</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <!--  -->

      <el-menu-item index="/administrator" @click="onGo('/administrator')">
        <img style="margin-right: 8px" src="@/assets/images/icon4.png" alt="">
        <span slot="title">管理员管理</span>
      </el-menu-item>
      <el-menu-item index="/role" @click="onGo('/role')">
        <img style="margin-right: 8px" src="@/assets/images/icon5.png" alt="">
        <span slot="title">角色管理</span>
      </el-menu-item>
      <el-menu-item index="/adminLog">
        <img style="margin-right: 8px" src="@/assets/images/icon6.png" alt="">
        <span slot="title">操作日志</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>

export default {
  data () {
    return {
      uuu: ''
    }
  },
  created () {
    this.getNav()
  },
  mounted () {
    console.log('navpath', this.$route.path)
  },
  methods: {
    onGo (url) {
      this.$router.push(url)
    },
    getNav () {
    }
  }
}
</script>

<style scoped>
.leftMenu {
  width: 190px;
}
>>> .el-submenu__title:hover {
  background: #1e469a !important;
  color: #fff !important;
}
>>> .el-submenu__title {
  border-radius: 5px;
}
>>> .el-menu-item.is-active {
  background: #eaf0ff !important;
}
>>> .el-menu {
  border: 0 !important;
}
.change {
  background: #1e469a;
}
</style>
